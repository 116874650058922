// document.addEventListener("DOMContentLoaded", function () {
//   const cursor = document.createElement("div");
//   cursor.style.width = "16px"; // Size of the dot
//   cursor.style.height = "16px";
//   cursor.style.borderRadius = "50%";
//   cursor.style.backgroundColor = "white";
//   cursor.style.position = "fixed";
//   cursor.style.border = "1px solid rgba(255, 255, 255, 0.18)";
//   cursor.style.backdropFilter = "blur(10px)";
//   cursor.style.pointerEvents = "none"; // Ignore the cursor when interacting with elements
//   cursor.style.zIndex = "10000"; // Ensure it's above everything
//   document.body.appendChild(cursor);

//   document.addEventListener("mousemove", function (e) {
//     cursor.style.left = e.pageX + "px";
//     cursor.style.top = e.pageY + "px";
//   });
//   const hoverElements = document.querySelectorAll(".hover-effect");
//   hoverElements.forEach((el) => {
//     el.addEventListener("mouseenter", () => {
//       cursor.style.transform = "scale(2)"; // Example: make the cursor larger
//       cursor.style.backgroundColor = "rgba(10, 36, 99, 0.5)"; // Example: change color to red
//     });
//     el.addEventListener("mouseleave", () => {
//       cursor.style.transform = "scale(1)"; // Revert to original size
//       cursor.style.backgroundColor = "white"; // Revert to original color
//     });
//   });
// });
